<template>
  <div class="row">
    <div class="col">
      <b-card title="Groups">
        <blueprint-data api-route-path="groups" :fields="fields">
          <template v-slot:default="{ state, item }">
            <b-form-group label="Name" label-for="name">
              <b-form-input id="name" v-model="item.name" :state="state('name')" type="text" placeholder="Name" />
            </b-form-group>
          </template>
        </blueprint-data>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      errors: {},
      fields: [
        {
          key: 'name',
          sortable: true,
        },
      ],
    };
  },
};
</script>
